import React from "react"
import "./weGrowAsWeGo.scss"
import { Divider, useMediaQuery, useTheme } from "@material-ui/core"
import { Flip, Slide } from "react-reveal"
import { StaticImage } from "gatsby-plugin-image"
// image
import gif1 from "../../assets/images/icons/GIF/customer.mp4"
import gif2 from "../../assets/images/icons/GIF/social-care.mp4"
import gif4 from "../../assets/images/icons/GIF/money-bag.mp4"

import { t } from "i18next"

const WeGrowAsWeGo = props => {
  const isMobileSmall = useMediaQuery(useTheme().breakpoints.down("xs"))
  const { borrowerData, loanData, lenderData } = props

  return (
    <div className="we-grow">
      <Flip bottom delay={1000}>
        <div className="section-title">
          <div className="title">
            {!isMobileSmall ? (
              <StaticImage
                src={"../../assets/images/picture/dot_orange.png"}
                alt="dot"
                className="dot-orange"
              />
            ) : (
              <></>
            )}

            {t(`home.we-grow-as-we-go`)}
          </div>
        </div>
      </Flip>
      {!isMobileSmall ? (
        <div className="content">
          <div className="content-row-1">
            <Slide left>
              <GifList gif={gif1} key={1} data={borrowerData} />
            </Slide>
            <Slide top>
              <GifList gif={gif2} key={2} data={lenderData} />
            </Slide>
            <Slide right>
              <GifList gif={gif4} key={3} data={loanData} />
            </Slide>
          </div>
        </div>
      ) : (
        <div className="content-mobile">
          <div className="data-card">
            <div className="main-card">
              <div>
                <video
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  playsInline={true}
                  className="gif"
                >
                  <source src={gif4} type="video/mp4" />
                </video>
              </div>
              <div className="additional-data">
                <div className="label">
                  {loanData[0].value === 0 ? "-" : loanData[0].textVal}
                </div>
                <div className="desc">{loanData[0].title}</div>
              </div>
            </div>
            <div className="sub-data">
              <div className="additional-data">
                <div className="label">
                  {loanData[1].value === 0 ? "-" : loanData[1].textVal}
                </div>
                <div className="desc">{loanData[1].title}</div>
              </div>
              <div className="vertical-divider" />
              <div className="additional-data">
                <div className="label">
                  {loanData[2].value === 0 ? "-" : loanData[2].textVal}
                </div>
                <div className="desc">{loanData[2].title}</div>
              </div>
            </div>
          </div>
          <div className="data-card">
            <div className="main-card">
              <div>
                <video
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  playsInline={true}
                  className="gif"
                >
                  <source src={gif1} type="video/mp4" />
                </video>
              </div>
              <div className="additional-data">
                <div className="label">
                  {borrowerData[0].value === 0 ? "-" : borrowerData[0].textVal}
                </div>
                <div className="desc">{borrowerData[0].title}</div>
              </div>
            </div>
            <div className="sub-data">
              <div className="additional-data">
                <div className="label">
                  {borrowerData[1].value === 0 ? "-" : borrowerData[1].textVal}
                </div>
                <div className="desc">{borrowerData[1].title}</div>
              </div>
              <div className="vertical-divider" />
              <div className="additional-data">
                <div className="label">
                  {borrowerData[2].value === 0 ? "-" : borrowerData[2].textVal}
                </div>
                <div className="desc">{borrowerData[2].title}</div>
              </div>
            </div>
          </div>{" "}
          <div className="data-card">
            <div className="main-card">
              <div>
                <video
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  playsInline={true}
                  className="gif"
                >
                  <source src={gif2} type="video/mp4" />
                </video>
              </div>
              <div className="additional-data">
                <div className="label">
                  {lenderData[0].value === 0 ? "-" : lenderData[0].textVal}
                </div>
                <div className="desc">{lenderData[0].title}</div>
              </div>
            </div>
            <div className="sub-data">
              <div className="additional-data">
                <div className="label">
                  {lenderData[1].value === 0 ? "-" : lenderData[1].textVal}
                </div>
                <div className="desc">{lenderData[1].title}</div>
              </div>
              <div className="vertical-divider" />
              <div className="additional-data">
                <div className="label">
                  {lenderData[2].value === 0 ? "-" : lenderData[2].textVal}
                </div>
                <div className="desc">{lenderData[2].title}</div>
              </div>
            </div>
          </div>
        </div>
      )}

      <StaticImage
        src={"../../assets/images/decoration/we-grow-circle.svg"}
        alt="circle"
        className="we-grow-circle-1"
      />
    </div>
  )
}

function GifList({ gif, data }) {
  return (
    <div className="content-row">
      <div>
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          className="gif"
        >
          <source src={gif} type="video/mp4" />
        </video>
      </div>

      {data.map((e, i) => {
        if (e.value === 0) {
          return <></>
        } else {
          return (
            <div className={`content-desc`} key={i}>
              <div className="name">
                <span>{e.title}</span>
              </div>
              <div className={"amount"}>
                <span>{e.value === 0 ? "-" : e.textVal}</span>
              </div>
              {i !== data.length - 1 && <Divider />}
            </div>
          )
        }
      })}
    </div>
  )
}

export default WeGrowAsWeGo
